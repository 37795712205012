  <template>
  <el-container style="height: 100%; width: 100%;">
    <el-header style="width: 100%; height: 100px; padding: 10px">
      <el-image
        style="width: 100%; height: 100px"
        src="https://oss.cniot.fun/sysProgram/head-logo.jpg"
      ></el-image>
    </el-header>
    <el-main class="public-scroll-transparent public-page-style iot-plan-main">
      <!-- <el-tabs
      class="public-scroll-transparent public-page-style iot-or-main"
      type="border-card"
      v-model="activeTab"
      @tab-click="handleClick"
    > -->
      <!-- <el-tab-pane label="方案中心" name="0">
        <div class="iot-type-items">
          <el-form :inline="true">
            <el-form-item label="方案名称：">
              <el-input
                size="mini"
                clearable
                placeholder="请输入方案名称"
                v-model="tableForm.planName"
                style="width: 300px"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                plain
                size="mini"
                @click.stop="selectData"
                >查询</el-button
              >
              <el-button
                class="plan-create-btn"
                type="primary"
                plain
                size="mini"
                v-show="
                  $store.state.permissionArr.indexOf('publicPlan:add') > -1
                "
                @click.stop="addPlan"
                >新建方案</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <el-table
          class="iot-table"
          :data="tableData"
          stripe
          border=""
          height="545"
          :default-sort="{ prop: 'date', order: 'descending' }"
          :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
        >
          <el-table-column prop="planName" label="方案名称"></el-table-column>
          <el-table-column label="方案状态" width="120">
            <template slot-scope="scope">
              <el-tag type="info" v-if="scope.row.planState == 0">草案</el-tag>
              <el-tag type="success" v-if="scope.row.planState == 1"
                >完成</el-tag
              >
            </template>
            ></el-table-column
          >
          <el-table-column label="方案图标" width="120">
            <template slot-scope="scope">
              <img
                style="width: 30px; height: 30px"
                :src="constant.OSS_URL + constant.PLAN_IMG + scope.row.planImg"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="gatewayNum"
            label="网关数量"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="bindingNum"
            label="项目数量"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            sortable=""
            width="150"
          ></el-table-column>
          <el-table-column label="操作" width="230">
            <template slot-scope="scope">
              <el-button
                style="margin-right: 30px"
                type="success"
                plain
                size="mini"
                v-show="$store.state.permissionArr.indexOf('plan:custom') > -1"
                @click="customPlan(scope.row.planId)"
                >获取方案</el-button
              >
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  查看详情
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-show="
                      $store.state.permissionArr.indexOf('plan:update') > -1
                    "
                    @click.native="editPlan(scope.row.planId, 1, true)"
                  >
                    <el-link :underline="false" type="info"> 基础设置 </el-link>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-show="
                      $store.state.permissionArr.indexOf('plan:update') > -1
                    "
                    @click.native="editPlan(scope.row.planId, 2, true)"
                  >
                    <el-link :underline="false" type="primary">
                      触发器设置
                    </el-link>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-show="
                      $store.state.permissionArr.indexOf('plan:update') > -1
                    "
                    @click.native="editPlan(scope.row.planId, 3, true)"
                  >
                    <el-link :underline="false" type="primary">
                      定时任务设置
                    </el-link>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-show="
                      $store.state.permissionArr.indexOf('publicPlan:update') >
                      -1
                    "
                    @click.native="jumpPreview(scope.row.planId, false, 'pc')"
                  >
                    <el-link :underline="false" type="primary">
                      PC组态页面设置
                    </el-link>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-show="
                      $store.state.permissionArr.indexOf('publicPlan:update') >
                      -1
                    "
                    @click.native="jumpPreview(scope.row.planId, false, 'app')"
                  >
                    <el-link :underline="false" type="primary">
                      公众号组态页面设置
                    </el-link>
                  </el-dropdown-item>
                  <el-dropdown-item
                    divided
                    @click.native="delPlan(scope.row.planId)"
                    ><el-link
                      :underline="false"
                      type="danger"
                      v-show="
                        $store.state.permissionArr.indexOf(
                          'publicPlan:delete'
                        ) > -1
                      "
                      >删除方案</el-link
                    ></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableForm.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="tableForm.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          style="text-align: right; margin-top: 10px; margin-right: 10px"
        ></el-pagination>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="私有方案" name="1"> -->
      <span
        style="
          font-size: 16px;
          margin: 20px 12px 20px 20px !important;
          display: block;
        "
      ></span>
      <div class="iot-type-items">
        <el-form :inline="true">
          <el-form-item label="方案名称：">
            <el-input
              size="mini"
              clearable
              placeholder="请输入方案名称"
              v-model="tableForm.planName"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain size="mini" @click.stop="selectData"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item class="newaddPlan">
            <el-button
              class="plan-create-btn"
              type="primary"
              plain
              size="mini"
              v-show="$store.state.permissionArr.indexOf('plan:add') > -1"
              @click.stop="addPlan"
              >新建方案</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="table-style">
        <el-table
          class="iot-table"
          :data="tableData"
          border=""
          height="545"
          :default-sort="{ prop: 'date', order: 'descending' }"
          :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
        >
          <el-table-column label="方案图标" width="80">
            <template slot-scope="scope">
              <img
                style="width: 30px; height: 30px"
                :src="constant.OSS_URL + constant.PLAN_IMG + scope.row.planImg"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="planName"
            width="230"
            label="方案名称"
          ></el-table-column>
          <el-table-column label="方案状态" width="100">
            <template slot-scope="scope">
              <el-tag type="info" v-if="scope.row.planState == 0">草案</el-tag>
              <el-tag type="success" v-if="scope.row.planState == 1"
                >完成</el-tag
              >
            </template>
            ></el-table-column
          >
          <el-table-column
            prop="serverType"
            :formatter="serverTypes"
            width="130"
            label="目标服务器"
          ></el-table-column>
          <el-table-column prop="gatewayNum" label="网关数量"></el-table-column>
          <el-table-column prop="bindingNum" label="项目数量"></el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            sortable=""
          ></el-table-column>
          <el-table-column label="操作" width="400">
            <template slot-scope="scope">
              <!-- v-show="$store.state.permissionArr.indexOf('plan:update') > -1" -->
              <el-dropdown
                v-if="scope.row.serverType == 0"
                trigger="click"
                style="margin-right: 10px"
              >
                <el-button
                  type="success"
                  class="el-dropdown-link"
                  plain
                  size="mini"
                >
                  方案编辑
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <!-- <span class="el-dropdown-link">
                    编辑操作
                    <i class="el-icon-arrow-down el-icon--right"></i> 
                  </span>-->
                <el-dropdown-menu slot="dropdown">
                  <!-- v-show="
                      $store.state.permissionArr.indexOf('plan:update') > -1
                    " -->
                  <el-dropdown-item
                    @click.native="editPlan(scope.row.planId, 1)"
                  >
                    <el-link :underline="false" type="primary">
                      基础设置
                    </el-link>
                  </el-dropdown-item>
                  <!-- v-show="
                      $store.state.permissionArr.indexOf('plan:update') > -1
                    " -->
                  <el-dropdown-item
                    @click.native="editPlan(scope.row.planId, 2)"
                  >
                    <el-link :underline="false" type="primary">
                      触发器设置
                    </el-link>
                  </el-dropdown-item>
                  <!-- v-show="
                      $store.state.permissionArr.indexOf('plan:update') > -1
                    " -->
                  <!-- <el-dropdown-item
                    @click.native="editPlan(scope.row.planId, 3)"
                  >
                    <el-link :underline="false" type="primary">
                      定时任务设置
                    </el-link>
                  </el-dropdown-item> -->
                  <!-- <el-dropdown-item
                    @click.native="editPlan(scope.row.planId, 5)"
                  >
                    <el-link :underline="false" type="primary">
                      实时计算
                    </el-link>
                  </el-dropdown-item> -->
                  <!-- v-show="
                      $store.state.permissionArr.indexOf('plan:update') > -1
                    " -->
                  <el-dropdown-item
                    @click.native="
                      jumpPreview(
                        scope.row.planId,
                        true,
                        'pc',
                        scope.row.planName
                      )
                    "
                  >
                    <el-link :underline="false" type="primary">
                      PC组态页面设置
                    </el-link>
                  </el-dropdown-item>
                  <!-- v-show="
                      $store.state.permissionArr.indexOf('plan:update') > -1
                    " -->
                  <el-dropdown-item
                    @click.native="
                      jumpPreview(
                        scope.row.planId,
                        true,
                        'app',
                        scope.row.planName
                      )
                    "
                  >
                    <el-link :underline="false" type="primary">
                      公众号组态页面设置
                    </el-link>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <el-dropdown-item
                      divided
                      @click.native="delPlan(scope.row.planId)"
                      ><el-link
                        :underline="false"
                        type="danger"
                        
                        >删除方案</el-link
                      ></el-dropdown-item
                    > -->
              <!-- <el-button
                type="warning"
                size="mini"
                plain
                v-if="
                  scope.row.serverType == 0 &&
                  $store.state.permissionArr.indexOf('plan:remoteUpdate') > -1
                "
                @click.native="updateShow(scope.row.planId)"
              >
                远程更新</el-button
              > -->
              <el-button
                type="primary"
                size="mini"
                plain
                v-if="
                  scope.row.serverType == 0 &&
                  $store.state.permissionArr.indexOf('plan:remoteUpdate') > -1
                "
                @click.native="copyShow(scope.row)"
              >
                分享</el-button
              >
              <el-button
                type="primary"
                size="mini"
                plain
                v-if="scope.row.serverType == 1"
                :underline="false"
                @click.native="editPlan(scope.row.planId, 1)"
              >
                基础设置</el-button
              >
              <el-button
                type="danger"
                plain
                size="mini"
                v-show="$store.state.permissionArr.indexOf('plan:delete') > -1"
                @click.native="delPlan(scope.row.planId)"
                >删除</el-button
              >
              <!-- <el-button
                  type="success"
                  plain
                  size="mini"
                  v-show="
                    $store.state.permissionArr.indexOf('plan:update') > -1
                  "
                  @click="editPlan(scope.row.planId)"
                  >修改</el-button
                > -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tableForm.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="tableForm.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        style="text-align: right; margin-top: 10px; margin-right: 10px"
      ></el-pagination>
      <!-- </el-tab-pane> -->
      <!-- </el-tabs> -->
    </el-main>
    <!-- 触发器设置 -->
    <el-dialog
      class="plan-dialog"
      v-if="planDialogVisinbile"
      :visible.sync="planDialogVisinbile"
      :destroy-on-close="true"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :fullscreen="false"
      :lock-scroll="true"
      top="3%"
      :before-close="beforeClose"
      :width="currentEdit == 2 || currentEdit == 3 ? '80%' : '50%'"
      style="overflow: hidden"
    >
      <div slot="title" style="width: 100%;">
        <el-row style="padding-top:15px">
  <el-col :span="4"> <img
          style="width: 25px; vertical-align: middle;"
          src="../assets/pencil.png"
        />  <span>{{ formTitle }}</span></el-col>
    <el-col :span="18"> <el-button
          style="float:right"
          type="primary"
            plain
            size="mini"
            @click="setTransfer"
            >排序</el-button
          ></el-col>
</el-row>
<!-- 排序 -->
<el-dialog :visible.sync="transferVis" v-if="transferVis" width="50%" append-to-body>
      <div style="padding: 0 15px" class="transferBox">
        <el-transfer
          v-model="transferValue"
          :data="transferData"
          target-order="push"
        ></el-transfer>
      </div>
      <span slot="footer" class="dialog-footer" style="margin-top:30px">
        <el-button
          type="primary"
          :disabled="transferValue.length != transferData.length"
          @click="sumbitTransfer()"
          >确 定</el-button
        >
        <el-button @click="transferVis = false">取 消</el-button>
      </span>
    </el-dialog>
        
      </div>
      <div v-if="currentEdit == 1">
        <planInfo
          ref="childPlanInfo"
          @subData="subData"
          :editData="planSubData"
          :currentPlanId="currentPlanId"
        ></planInfo>
        <gatewayConfig
          ref="childGateway"
          @subData="subData"
          :editData="planSubData"
          :currentPlanId="currentPlanId"
        ></gatewayConfig>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" plain size="mini" @click="addPlanForm()"
            >提交</el-button
          >
          <el-button
            type="info"
            size="mini"
            plain
            @click="planDialogVisinbile = false"
            >关闭</el-button
          >
        </span>
      </div>
      <div v-if="currentEdit == 2">
        <trigger
          ref="triggerRef"
          v-if="currentEdit == 2"
          :currentPlanId="currentPlanId"
        ></trigger>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            size="mini"
            class="iot-trigger-create"
            @click="alarmSolutionVisibile = true"
            v-show="$store.state.permissionArr.indexOf('solution:view') > -1"
            >解决方案</el-button
          >
          <el-button
            type="primary"
            plain
            size="mini"
            class="iot-trigger-create"
            v-show="$store.state.permissionArr.indexOf('alarm:add') > -1"
            @click="$refs.triggerRef.addTrigger()"
            >新增</el-button
          >
          <el-button
            type="info"
            size="mini"
            plain
            @click="planDialogVisinbile = false"
            >关闭</el-button
          >
        </span>
      </div>
      <div v-if="currentEdit == 3">
        <timedTask
          ref="timedTaskRef"
          v-if="currentEdit == 3"
          :currentPlanId="currentPlanId"
        ></timedTask>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            plain
            size="mini"
            class="iot-trigger-create"
            v-show="$store.state.permissionArr.indexOf('alarm:add') > -1"
            @click="$refs.timedTaskRef.addTask()"
            >新增</el-button
          >
          <el-button
            type="info"
            size="mini"
            plain
            @click="planDialogVisinbile = false"
            >关闭</el-button
          >
        </span>
      </div>
      <!--数据应用  -->
      <div v-if="currentEdit == 5">
        <application
          ref="applicationRef"
          v-if="currentEdit == 5"
          :currentPlanId="currentPlanId"
        ></application>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            plain
            size="mini"
            class="iot-trigger-create"
            v-show="
              $store.state.permissionArr.indexOf('dataApplication:add') > -1
            "
            @click="$refs.applicationRef.addapplication()"
            >新增</el-button
          >
          <el-button
            type="info"
            size="mini"
            plain
            @click="planDialogVisinbile = false"
            >关闭</el-button
          >
        </span>
      </div>
      <configuration
        v-if="currentEdit == 4"
        @finish="finish"
        :isNew="isNew"
        :currentPlanId="currentPlanId"
      ></configuration>
    </el-dialog>
    <el-dialog
      top="3%"
      title="解决方案"
      :visible.sync="alarmSolutionVisibile"
      :destroy-on-close="true"
    >
      <alarmSolution v-if="alarmSolutionVisibile" :planId="currentPlanId">
      </alarmSolution>
    </el-dialog>
    <el-dialog
    top="3%"
      title="远程更新"
      :visible.sync="showUpdateVisibile"
      :before-close="beforeCloseUpdate"
      :destroy-on-close="true"
    >
      <div>
        <el-form
          ref="uploadDtuRef"
          :inline="true"
          :model="uploadForm"
          class="demo-form-inline"
        >
          <el-form-item label="网关类型">
            <el-select
              v-model="uploadForm.gatewayId"
              placeholder="选择网关类型"
              @change="gatewayChange"
            >
              <el-option
                v-for="item in gatewayList"
                :key="item.gatewayId"
                :label="item.gatewayName"
                :value="item.gatewayId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="SN" v-if="dtuSns.length > 0">
            <el-select
              multiple
              v-model="uploadForm.dtuSns"
              placeholder="请选择SN"
              collapse-tags
            >
              <el-option
                v-for="item in dtuSns"
                :key="item.dtuSn"
                :label="item.dtuSn"
                :value="item.dtuSn"
              >
                <span style="float: left">
                  <i
                    style="color: #909399"
                    v-if="item.state == 1"
                    class="el-icon-s-help"
                  ></i>
                  <i
                    style="color: #409eff"
                    v-if="item.state == 2"
                    class="el-icon-loading"
                  ></i>
                  <i
                    style="color: #67c23a"
                    v-if="item.state == 3"
                    class="el-icon-s-help"
                  ></i>
                  <i
                    style="color: #f56c6c"
                    v-if="item.state == 4"
                    class="el-icon-s-help"
                  ></i>
                  {{ item.dtuSn }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="updateSn">升级</el-button>
          </el-form-item>
        </el-form>
        <div style="width: 100%; height: 387px; overflow-y: auto">
          <el-table :data="stateList" style="width: 100%">
            <el-table-column prop="dtuSn" label="SN"> </el-table-column>
            <el-table-column prop="gatewayName" label="网关名称">
            </el-table-column>
            <el-table-column prop="startTime" label="更新时间">
            </el-table-column>
            <el-table-column prop="endTime" label="结束时间"> </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-tag
                  type=""
                  v-if="scope.row.state == 2"
                  :disable-transitions="true"
                >
                  正在更新...
                </el-tag>
                <el-tag
                  type="danger"
                  title="点击再次升级"
                  v-else-if="scope.row.state == 4"
                  @click="singleUpdate(scope.row.dtuSn)"
                >
                  更新失败
                </el-tag>
                <el-tag
                  type="success"
                  v-else-if="scope.row.state == 3"
                  @click="singleUpdate(scope.row.dtuSn)"
                >
                  更新成功
                </el-tag>
                <el-tag
                  type="info"
                  v-else-if="scope.row.state == 1"
                  @click="singleUpdate(scope.row.dtuSn)"
                >
                  未更新
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="方案分享"
      top="12%"
      width="30%"
      :visible.sync="copyVisibile"
    >
      <div>
        <el-form ref="form" :rules="rules" label-width="100px">
          <el-form-item label="新方案名称" prop="planName">
            <el-input
              v-model="copyFrom.planName"
              placeholder="请输入方案名称"
            ></el-input>
          </el-form-item>
          <el-radio-group v-model="copyUser">
            <el-form-item label="分享目标">
              <div style=" height: 40px">
                <el-radio label="0">复制给自己</el-radio>
              </div>
            </el-form-item>

            <el-form-item label="">
              <div style="display: flex">
                <div style=" height: 40px;margin-right:20px;">
                <el-radio label="1">分享给其他用户</el-radio>
              </div>
                
                <el-input
                  v-model="copyFrom.freeUsername"
                  placeholder="请输入用户名"
                ></el-input>
              </div>
            </el-form-item>
          </el-radio-group>
        </el-form>
      </div>
      <div slot="footer" class="copy-dialog-footer">
        <el-button type="primary" plain size="mini" @click="copyPlan"
          >确 定</el-button
        >
        <el-button type="info" plain size="mini" @click="copyVisibile = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
import { TABLE_HEADER_COLOR } from "../util/theme";
import { OSS_URL, PLAN_IMG } from "../util/constant";
import planInfo from "../components/PlanInfo";
import gatewayConfig from "../components/gatewayConfig";
import trigger from "../components/trigger";
import timedTask from "../components/timedTask";
import configuration from "../components/Configuration";
import application from "../components/application";
import { addressUrl } from "../util/global";
import alarmSolution from "../components/alarmSolution";
import HMIInterface from "../util/draw/HMIInterface.js";
import HMIPrj from "../util/draw/HMIProject.js";
let that;
export default {
  name: "Plan",
  components: {
    planInfo,
    gatewayConfig,
    trigger,
    timedTask,
    configuration,
    application,
    alarmSolution,
  },
  data() {
    return {
      transferData: [],
      transferValue: [],
      transferVis:false,
      copyFrom: {
        planId: "",
        planName: "",
        username: "",
        freeUsername:"",
      },
      copyUser: "0",
      copyVisibile: false,
      alarmSolutionVisibile: false,
      dtuSns: [],
      uploadForm: {
        gatewayId: "",
        dtuSns: [],
      },
      gatewayList: [],
      constant: { OSS_URL, PLAN_IMG },
      theme: { TABLE_HEADER_COLOR },
      planDialogVisinbile: false,
      tableForm: {
        size: 10,
        current: 1,
        isPublic: 1,
        planName: "",
      },
      tableData: [],
      total: 0,
      activeTab: "",
      isNew: true,
      currentPlanId: 0,
      formTitle: "",
      currentEdit: 1,
      planSubData: {},
      isCreate: true,
      isPublic: 0,
      checkPublic: false,
      showUpdateVisibile: false,
      timer: null,
      stateList: [],
      rules: {
        // name: [
        //   { required: true, message: '请输入用户名', trigger: 'blur' },
        // ],
        // planName: [
        //   { required: true, message: '请输入方案名称', trigger: 'blur' },
        // ],
      },
      copyData: {},
    };
  },
  methods: {
    //排序
    setTransfer() {
      that.$api.plan.setTransfer(that.currentPlanId).then((res) => {
        if (res.code == 200) {
          let arr = [];
          res.data.forEach((item) => {
            arr.push({
              key: item.alarmId,
              label: item.alarmName,
            });
          });
          that.transferData = arr;
          that.transferValue = [];
          that.transferVis = true;
        }
      });
    },
    sumbitTransfer() {
      that.$api.plan.updateSort(that.transferValue).then((res) => {
        if (res.code == 200) {
          that.transferVis = false;
          that.$message.success(res.msg || '修改成功')
          that.$refs.triggerRef.getTriggerPage()
        }
      });
    },
    copyPlan() {
      if (!that.copyFrom.planName) {
        that.$message({
          type: "warning",
          message: "方案名称不能为空！",
        });
        return;
      }
      that.copyFrom.username=that.copyFrom.freeUsername;
      if (that.copyUser == 0) {
        that.copyFrom.username = that.$store.state.username;
      } else if (!that.copyFrom.username) {
        that.$message({
          type: "warning",
          message: "分享用户的用户名不能为空！",
        });
        return;
      }
      const loading = this.$loading({
          lock: true,
          text: '分享中，请稍后',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      that.$api.plan.copyPlan(that.copyFrom).then((res) => {
        loading.close();
        if (res.code == 200) {
          that.$store.commit("setPlatType", "pc");
                  that.getPlanPage();
                  that.copyVisibile = false;
          that.$store.commit("setPlatType", "pc");
          HMIInterface.readCommSuccess = (data) => {};
          HMIInterface.loginSuccess = (data) => {
            HMIPrj.vHMIPrjFunc.loadProject(data);
            new Promise((resolve, reject) => {
              HMIPrj.vHMIPrjFunc.setNewCtrls(res.data, resolve);
            }).then(() => {

              HMIInterface.loginSuccess = (data) => {
                HMIPrj.vHMIPrjFunc.loadProject(data);
                new Promise((resolve, reject) => {
                  HMIPrj.vHMIPrjFunc.setNewCtrls(res.data, resolve);
                }).then(() => {
                  that.$store.commit("setPlatType", "pc");
                  that.getPlanPage();
                  that.copyVisibile = false;
                });
              };
              that.$store.commit("setPlatType", "app");
              HMIInterface.planID = that.copyFrom.planId;
              HMIInterface.loginDirect(that.$store.state.token);
            });
          };
          HMIInterface.planID = that.copyFrom.planId;
          HMIInterface.loginDirect(that.$store.state.token);
        }
      });
    },
    copyShow(item) {
      // console.log(item);
      that.copyFrom.planId = item.planId;
      that.copyFrom.planName = item.planName;
      that.copyFrom.username = "";
      that.copyFrom.freeUsername = "";
      that.copyVisibile = true;
    },
    serverTypes(value) {
      switch (value.serverType) {
        case 0:
          return "云平台";
        case 1:
          return "第三方";

        default:
          break;
      }
    },
    singleUpdate: function (dtuSn) {
      that.$set(that.uploadForm, "dtuSns", [dtuSn]);
      that.updateSn();
    },
    beforeCloseUpdate: function () {
      that.showUpdateVisibile = false;
      that.dtuSns = [];
      that.uploadForm = {
        gatewayId: "",
        dtuSns: [],
      };
      if (!that.timer) return;
      clearInterval(that.timer);
    },
    updateSn: function () {
      that.$api.plan.updateSn(that.uploadForm).then((response) => {
        if (response.data) {
          that.uploadForm = {
            gatewayId: "",
            dtuSns: [],
          };
        }
      });
    },
    gatewayChange: function (d) {
      that.$api.plan.getdtuSns(d).then((response) => {
        if (response.code == 200) {
          that.dtuSns = response.data;
        }
      });
    },
    updateShow: function (planId) {
      that.planGetgateway(planId);
    },
    planGetgateway: function (planId) {
      that.$api.plan.planGetgateway(planId).then((response) => {
        if (response.code == 200) {
          that.gatewayList = response.data;
          that.showUpdateVisibile = true;
          that.timer = setInterval(() => {
            that.getState(planId);
          }, 2000);
        }
      });
    },
    getState: function (planId) {
      that.stateList = [];
      that.$api.plan.getResult(planId).then((response) => {
        if (response.code == 200) {
          that.stateList = response.data;
        }
      });
    },
    subData: function (data) {
      if (data.planName) {
        that.$set(that.planSubData, "planName", data.planName);
        that.$set(that.planSubData, "showType", data.showType);
        that.$set(that.planSubData, "planImg", data.planImg);
      } else {
        that.$set(that.planSubData, "serverId", data.serverId);
        that.$set(that.planSubData, "isChangeUpload", data.isChangeUpload);
        that.$set(that.planSubData, "isUploadValid", data.isUploadValid);
        that.$set(that.planSubData, "serverType", data.serverType);
        that.$set(that.planSubData, "gatewayId", data.gatewayId);
        that.$set(that.planSubData, "gatewayIds", [data.gatewayId]);
        that.$set(that.planSubData, "uploadPeriod", data.uploadPeriod);
        that.$set(that.planSubData, "uploadMode", data.uploadMode);
        that.$set(that.planSubData, "isCarryId", data.isCarryId);
      }
    },
    addPlanForm: function () {
      that.$refs.childGateway.submitForm();
      that.$refs.childPlanInfo.submitForm();
      let ewayId = false;
      if (
        that.planSubData.gatewayId != "" ||
        that.planSubData.gatewayIds.length > 0
      ) {
        ewayId = true;
      }
      if (!ewayId) {
        // console.log(1111);
        this.$message({
          type: "error",
          message: "请选择网关！",
        });
      }
      if (
        that.planSubData.planName != "" &&
        that.planSubData.uploadPeriod >= 2 &&
        that.planSubData.uploadPeriod <= 86400 &&
        ewayId
      ) {
        let _api;
        let flag = false;

        if (that.isCreate && that.isPublic == 0) {
          _api = that.$api.plan.addPublicPlan(that.planSubData);
        } else if (that.isCreate && that.isPublic == 1) {
          _api = that.$api.plan.addPlan(that.planSubData);
        } else if (!that.isCreate && that.isPublic == 0) {
          _api = that.$api.plan.editPublicPlan(that.planSubData);
        } else if (!that.isCreate && that.isPublic == 1) {
          _api = that.$api.plan.editPlan(that.planSubData);
          flag = true;
        }

        _api.then((response) => {
          if (response.code == 200) {
            that.planDialogVisinbile = false;
            that.isCreate = true;
            that.getPlanPage(that.tableForm);
            if (flag) {
              if (that.planSubData.serverType == 0) {
                that.$message({
                  type: "success",
                  message: response.msg,
                  showClose: true,
                  center: true,
                });
              }
            }
          }
        });
      }
    },
    addPlan() {
      that.currentEdit=1
      that.formTitle = "新增方案";
      that.planDialogVisinbile = true;
      that.currentPlanId = 0;
      that.planSubData = {};
      that.isNew = true;
      that.isCreate = true;
    },
    editPlan(planId, type, isPublic = false) {
      that.currentPlanId = planId;

      that.$api.plan.planDetail(planId).then((response) => {
        if (response.code == 200) {
          that.planSubData = response.data;
          that.$set(this,'planSubData',response.data)
          that.formTitle = "修改方案";
          that.isNew = false;
          that.currentEdit = type;
          that.isCreate = false;
          that.checkPublic = isPublic;
          that.planDialogVisinbile = true;
        }
      });
    },
    customPlan(planId) {
      that.$api.plan.customPlan(planId).then((res) => {
        if (200 == res.code) {
          that.formTitle = "获取方案";
          that.planDialogVisinbile = true;
          that.currentPlanId = res.data;
        }
      });
    },
    delPlan(id) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确 定",
          cancelButtonText: "取 消",
          type: "warning",
          cancelButtonClass: "cancelBtn",
        })
        .then(() => {
          that.$api.plan.delPlan(id).then((response) => {
            if (response.code == 200) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.getPlanPage();
            }
          });
        })
        .catch(() => {});
    },
    handleClick(step) {
      that.$set(that.tableForm, "isPublic", Number(step.index));
      that.$set(that.tableForm, "current", 1);
      that.getPlanPage();
    },

    finish(dialogVisinbile) {
      that.planDialogVisinbile = dialogVisinbile;
      that.isNew = false;
    },
    handleSizeChange(val) {
      that.$set(that.tableForm, "size", val);
      that.$set(that.tableForm, "current", 1);
      that.getPlanPage();
    },
    handleCurrentChange(val) {
      that.$set(that.tableForm, "current", val);
      that.getPlanPage();
    },
    selectData() {
      that.$set(that.tableForm, "current", 1);
      that.getPlanPage();
    },
    getPlanPage() {
      that.$api.plan.getPlanPage(that.tableForm).then((response) => {
        if (response.code == 200) {
          that.tableData = response.data.records;
          that.total = response.data.total;
        }
      });
    },
    beforeClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
          that.planDialogVisinbile = false;
          that.currentEdit = 1;
        })
        .catch(() => {});
    },
    jumpPreview: function (planId, isEditor = false, platType, name) {
      this.$store.commit("setPlatType", platType);
      this.$store.commit("setPId", planId);
      window.localStorage.setItem("HMIName", name);
      if (isEditor) {
        window.localStorage.setItem(
          "permissions",
          that.$store.state.permissionArr
        );
        window.open(
          `${addressUrl}#/configuration/${this.$store.state.token}/${this.$store.state.tokenId}/${this.$store.state.pId}/${platType}?uId=${this.$store.state.userId}`
        );
      } else {
        window.open(
          `${addressUrl}#/hmiexecute/${this.$store.state.token}/${this.$store.state.tokenId}/${this.$store.state.pId}/${platType}?uId=${this.$store.state.userId}`
        );
      }
    },
  },
  beforeMount() {
    that = this;
  },
  mounted() {
    // that.zoom
    let userId = that.$store.state.userId;
    if (1 == userId) {
      that.activeTab = "0";
      that.isPublic = 0;
      that.$set(that.tableForm, "isPublic", 0);
    } else {
      that.activeTab = "1";
      that.isPublic = 1;
      that.$set(that.tableForm, "isPublic", 1);
    }
    that.getPlanPage(that.tableForm);
  },
};
</script>
<style scoped>
.iot-or-main {
  position: relative;
  height: calc(100% - 100px);
  margin-top: 20px;
}
.iot-or-main >>> .el-tabs__item:hover,
.iot-or-main >>> .el-tabs__item:focus,
.iot-or-main >>> .el-tabs__item {
  color: #303133;
}
.iot-or-main >>> .el-tabs__content {
  padding: 15px 15px 0 15px;
}
.iot-type-items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px;
  box-sizing: border-box;
}
.iot-type-items form {
  width: 100%;
  position: relative;
}
.iot-type-items form .newaddPlan {
  position: absolute;
  top: 0;
  right: 0;
}
.iot-type-item {
  margin: 5px 10px;
  cursor: pointer;
  border-radius: 20px;
  border: none;
}
.iot-plan >>> .iot-step-body {
  width: 100%;
  height: calc(100% - 40px);
  padding: 30px;
}
.table-style {
  margin: 0px 10px 0 20px;
}
.iot-table {
  margin: 0 auto;
}
.public-page-style {
  padding: 0;
  padding-bottom: 10px;
}
.plan-dialog >>> .el-dialog {
  height: 775px;
}
.plan-dialog >>> .el-dialog__body {
  padding: 0;
  height: calc(775px - 120px);
  overflow-x: hidden;
  overflow-y: auto;
}
.plan-create-btn {
  /* position: fixed; */
  /* margin-left: 35%; */
  margin-top: 5px;
}
.iot-plan-main {
  position: relative;
  height: calc(100% - 100px);
  width: calc(100% - 25px);
  margin: 20px 12px 0px 12px !important;
  overflow: hidden;
}
.dialog-footer {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 0;
}
.transferBox {
  display: flex;
  justify-content: center;
}
</style>